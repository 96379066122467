.add-product {
  max-width: 100%;
  margin: 10px;
  padding: 10px 20px;
  background: #f4f7f8;
  border-radius: 8px;
  color: #384047;
}

.add-product h1 {
  margin: 0 0 30px 0;
  text-align: center;
}

.add-product input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea,
select {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  font-size: 16px;
  height: auto;
  margin: 0;
  outline: 0;
  padding: 15px;
  width: 100%;
  background-color: #e8eeef;
  color: #8a97a0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  margin-bottom: 30px;
}

.add-product input[type="radio"],
input[type="checkbox"] {
  margin: 0 4px 8px 0;
}

.add-product select {
  padding: 6px;
  height: 32px;
  border-radius: 2px;
}

.add-product button {
  padding: 19px 39px 18px 39px;
  color: #fff;
  background-color: #4bc970;
  font-size: 18px;
  text-align: center;
  font-style: normal;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #3ac162;
  border-width: 1px 1px 3px;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
  margin-bottom: 10px;
}

.add-product fieldset {
  /* margin-bottom: 30px; */
  border: none;
}

.add-product legend {
  font-size: 1.4em;
  margin-bottom: 10px;
}

.add-product label {
  display: block;
  margin-bottom: 8px;
}

.add-product label.light {
  font-weight: 300;
  display: inline;
}

.add-product .number {
  background-color: #5fcf80;
  color: #fff;
  height: 30px;
  width: 30px;
  display: inline-block;
  font-size: 0.8em;
  margin-right: 4px;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 100%;
}

@media screen and (min-width: 480px) {
  .add-product {
    max-width: 33%;
  }
}
